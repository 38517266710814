.service-box{
    width: 100%;
    height: 100vh;
    background-color: black;
}

.serviceTitle{
    color: white;
    display: flex;
    justify-content: center;
    font-size: 35px;
    padding-top: 30px;
    font-family: 'Poppins';
    font-weight: bold;
    gap: 0.8rem;
}

.serviceTitle span{
    color: rgb(112, 164, 164);
}

.box-membership{
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding-top: 60px;
}

.bronze,.silver{
    width: 280px;
    height: 350px;
    background-color: rgb(35, 87, 138);
    margin-top: 30px;
}

.gold{
    width: 320px;
    height: 410px;
    background-color: rgb(71, 56, 94);
}

.crown img,.basic img,.average img{
    width: 50px;
    height: 50px;
    padding-top: 12px;
    padding-left: 12px;
}

.planName{
    font-size: 20px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-family: 'Poppins';
    color: white;
}

.costPlan{
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    justify-content: center;
}

.benefit{
    margin-top: 30px;
}

.benefit img{
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
}

.benefitList{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 1rem;
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-left: 7px;
}

.btnJoin{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.btnJoinPremium{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.boxBtn{
    background-color: white;
    width: 140px;
    height: 35px;
}

.textBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: bold;
    margin-top: 5px;
}

.gold:hover{
    background-color: rgb(145, 85, 255);
    transition: 0.8s;
    cursor: pointer;
}
.bronze:hover,.silver:hover{
    background-color: rgb(81, 78, 255);
    transition: 0.8s;
    cursor: pointer;
}

.boxBtn:hover{
    cursor: pointer;
    background-color: blueviolet;
    
}

.textBtn:hover{
    color: white;
}

@media screen and (max-width :500px) {
    .serviceTitle{
        font-size: 20px;
        padding-top: 15px;
    }
    .box-membership{
        justify-content: space-around;
        align-items: center;
        padding-top: 30px;
    }
    .bronze,.silver{
        width: 200px;
        height: 220px;
        margin-top: 0;
    }
    .gold{
        position: absolute;
        margin-top: 480px;
        width: 250px;
        height: 220px;
    }
    .benefit{
        margin-top: 10px;
    }
    .basic img,.average img,.crown img{
        width: 20px;
        height: 20px;
    }
    .planName{
        font-size: 10px;
    }
    .costPlan{
        font-size: 12px;
    }
    .benefit img{
        width: 20px;
        height: 15px;
        padding-left: 10px;
    }
    .benefitList{
        font-size: 12px;
        margin-bottom: 5px;
        margin-left: 0;
    }
    .btnJoin{
        margin-top: 0;
    }
    .btnJoinPremium{
        padding-top: 20px;
    }
    .boxBtn{
        width: 100px;
        height: 30px;
    }
    .textBtn{
        font-size: 8px;
    }
}