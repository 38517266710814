.box-header{
    width: 100%;
    height: 100vh;
    background-image: url(/public/image/background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.top{
    display: flex;
    justify-content: space-around;
    padding-top: 40px;
}

.title{
    text-transform: uppercase;
    font-size: 45px;
    font-weight: bold;
    color: rgb(112, 164, 164);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 40px;
    margin-left: 10px;
}


.title>span{
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 1px;
    color: transparent;
    background: url(/public/image/back.png);
    background-clip: text;
    background-position: 0 0;
    animation: back 20s linear infinite;
    background-size: cover;
}

@keyframes back{
    100%{
        background-position: 1000px 0;
    }
}

.menu-bar{
    color: white;
    display: flex;
    gap: 1rem;
    font-size: 16px;
    margin-left: 100px;
    font-weight: bold;
}

.signUp{
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
}

.signUp:hover,.logIn:hover{
    background-color: rgb(112, 164, 164);
    width: 100px;
    height: 40px;
}

.logIn{
    border: 2px solid white;
    border-radius: 10px;
}

.logIn,.a,.b,.c{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 40px;
    cursor: pointer;
}
.signUp-logIn{
    color: white;
    display: flex;
    font-size: 16px;
    gap: 1.2rem;
    font-weight: bold;
}

.a:hover,.b:hover,.c:hover{
    color: blueviolet;
}

.center{
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
}
.center-left{
    color: white;
    font-size: 65px;
    font-weight: bold;
    width: 520px;
    height: 300px;
    margin-left: 40px; 
    padding-top: 30px;
}


.text span{
    color: rgb(112, 164, 164);
}

.center-left-bottom{
    color: white;
    font-size: 17px;
    margin-left: 40px;
    width: 230px;
    height: 100px;
   
}

.photo-music img{
    width: 490px;
    height: 471px;
}

@media screen and (max-width :500px) {
    .box-header{
        background-position: -250px 0;
        background-attachment: fixed;
    }
    .title{
        margin-left: 70px;
    }
    .top{
        flex-direction: column;
    }
    .menu-bar{
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
        gap: 0.5rem;
        padding-top: 40px;
        padding-bottom: 20px;
    }

   
    .signUp-logIn{
        padding-top: 20px;
        justify-content: center;
        align-items: center;
    }
    .center-left{
        color: white;
        font-size: 45px;
        padding-top: 10px;
        width: 320px;
        height: 200px;
        margin-left: 20px;
    }
    .center-left-bottom{
        color: white;
        font-size: 14px;
        margin-left: 20px;
    }
    .photo-music img{
        display: none;
    }
}