.contact-header{
    width: 100%;
    height: 250px;
    background-color: rgb(34, 25, 54);
}

.logoTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 65px;
    color: rgb(112, 164, 164);
    
}
.logoTitle span{
   
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 1px;
    color: transparent;
}

.sosmed{
    display: flex;
    justify-content: center;
    gap: 2.8rem;
    padding-top: 35px;
}

.sosmed img{
    width: 28px;
    height: 28px;
}

.sosmed img:hover{
    cursor: pointer;
}

@media screen and (max-width :500px) {
    .logoTitle{
        font-size: 25px;
    }
}