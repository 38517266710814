.box{
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(rgb(71, 77, 173),rgb(123, 64, 162));
}

/* .blur{
    background: rgb(255, 115, 90);
    filter: blur(212px);
    width: 250px;
    height: 100px;
    position: absolute;
    z-index: -9;
    margin-top: 50px;
}
.blurtodayTop{
        background: rgb(255, 115, 90);
        filter: blur(212px);
        width: 250px;
        height: 100px;
        position: absolute;
        z-index: -9;
        margin-top: 50px;
        margin-left: 220px;
    
} */
.title-header{
    display: flex;
    justify-content: space-evenly;
    padding-top: 60px;
}

.header-left{
    width: 680px;
    height: 450px;
}

.left-photo img{
    width: 680px;
    height: 250px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
}

.textHuge{
    font-size: 62px;
    font-weight: bold;
    height: 130px;
    width: 600px;
    text-transform: uppercase;
}

.textHuge span{
    -webkit-text-stroke-color: rgb(255, 255, 255);
    -webkit-text-stroke-width: 1px;
    color: transparent;
}

.textSmall{
    width: 500px;
    height: 130px;
    padding-top: 16px;
}

.left-bottom-container{
    display: flex;
    justify-content: space-around;
    
}

.btnSeeAll{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.boxSeeAll{
    background-color: rgb(61, 29, 88);
    width: 180px;
    height: 60px;
    border-radius: 20px;
}

.txtSeeAll{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    color: white;
    font-family: 'Poppins';
    gap: 0.7rem;
}

.boxSeeAll:hover{
    background-color: rgb(29, 15, 40);
    transition: 0.7s;
    cursor: pointer;
}

.header-right{
    width: 450px;
    height: 450px;
    background-color: rgb(19, 22, 75);
    border-radius: 20px;
}

.titleTrack{
    padding-top: 20px;
    color: white;
    display: flex;
    justify-content: center;
    gap: 2rem;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    
}

.topTodayDisplay{
    margin-top: 20px;
}

.topTodayDisplay,.newSongDisplay,.discoverBackgroundDisplay{
    display: flex;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.topTodayBackground,.newSongBackgorund,.discoverSongBackground{
    background-color: rgb(123, 84, 202);
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    width: 280px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    
}


.topToday img,.newSong img,.discoverSong img{
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    width: 180px;
    height: 100px;
}

.topTodayBackground:hover,.newSongBackgorund:hover,.discoverSongBackground:hover{
    cursor: pointer;
    background-color: rgb(58, 27, 91);
    transition: 0.7s;
}

.topTodayBackground:hover,.newSongBackgorund:hover,.discoverSongBackground:hover{
    color: white;
}

@media screen and (max-width :500px) {
    .title-header{
       flex-direction: column;
       justify-content: center;
       align-items: center;
       padding-top: 15px;
    }
    .header-left{
        width: 420px;
        height: 200px;
    }
    .left-photo{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .left-photo img{
        width: 300px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
    }
    .left-bottom-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .textHuge{
        font-size: 40px;
        width: 420px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .textSmall{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        width: 400px;
        height: 40px;
        padding-top: 30px;
    }
    .btnSeeAll{
        margin-top: 25px;
    }
    .boxSeeAll{
        width: 80px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .txtSeeAll{
        font-size: 10px;
        margin-top: 0;
    }
    .header-right{
        width: 400px;
        height: 320px;
    }
    .titleTrack{
        font-size: 14px;
    }
    .topTodayBackground,.discoverSongBackground,.newSongBackgorund{
        width: 250px;
        height: 60px;
        font-size: 14px
    }
    .topToday img,.newSong img,.discoverSong img{
        width: 100px;
        height: 60px;
    }
}
